import React from "react"

export const SessionContext = React.createContext()
export const SessionDispatchContext = React.createContext()

const initialSession = {
  name: "",
  workEmail: "", 
  workPhone: "",
  profilePicture: "",
  preferences: [],
  preferredBrand: "",
  preferredRoleId: "",
  newInspections: 0,
  unfinishedInspections: 0,
  roles: [],
  brands: [],
  activeRole: {},
  applications: 0,
  userCount: 0,
};


function sessionReducer(session, action) {
  switch(action.type) {
    case "UPDATE_SESSION": {
      //console.log(action.value);
      let sessionCopy = {...session};
      sessionCopy = action.value;
      //productCatalogCopy.targetInfo.workNumber.value = action.value;
      //productCatalogCopy.targetInfo.workNumber.isValid = workNumberValid;
      //console.log(credentialsCopy);
      sessionStorage.setItem("session", JSON.stringify(sessionCopy));

        return {
          //...credentials,
          ...sessionCopy,
        }
    }
    case "REMOVE_SESSION": {
      sessionStorage.clear();
      let sessionCopy = {...session};
      sessionCopy.name = "";
      sessionCopy.workEmail = "";
      sessionCopy.workPhone = "";
      sessionCopy.profilePicture = "";
      sessionCopy.preferences = sessionCopy.preferences.slice(sessionCopy.preferences.length);
      sessionCopy.newInspections = 0;
      sessionCopy.unfinishedInspections = 0;
      sessionCopy.roles = sessionCopy.roles.slice(sessionCopy.roles.length);
      sessionCopy.brands = sessionCopy.brands.slice(sessionCopy.brands.length);
      return {
        ...session,
        ...sessionCopy,
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const checkSessionStorageSession = (initialSession)  => {
  //console.log(JSON.parse(sessionStorage.getItem("roles")));
  const session = JSON.parse(sessionStorage.getItem("session"));
  //console.log(sessionRoles)
  if(session !== null) {
    initialSession = session;
    return session;
  }
  else return initialSession;
}

const SessionProvider = ({ children }) => {
  const [session, dispatch ] = React.useReducer(sessionReducer, initialSession, checkSessionStorageSession)

  return(
    <SessionContext.Provider value={session}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionContext.Provider>
  )
}

export default SessionProvider;

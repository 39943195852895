import React from "react";
import { Box, Typography, Button, TextField, Stack, CircularProgress } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TranslationContent from "../../Translations/TranslationContent";
import { Tokens } from "../../Services/tokens";
// import applicationService from "../../services/applications";
// import { logger } from "../../services/logger";


const ErrorStatus = ({ error, activeAccount }) => {
  let [formSent, setFormSent] = React.useState(false);
  let [sentError, setSentError] = React.useState(false);
  let [sendingForm, setSendingForm] = React.useState(false);
  let [form, setForm] = React.useState(
    {
      requestedOrganization: "",
      phoneNumber: "",
      additionalInformation: "",
    }
  )

  const handleFormInputChange = (event, type) => {
    let updatedForm = {...form};
    if(type === "requestedOrganization") {
      updatedForm.requestedOrganization = event.target.value;
    }
    if(type === "phone") {
      updatedForm.phoneNumber = event.target.value;
    }
    if(type === "additionalInfo") {
      updatedForm.additionalInformation = event.target.value;
    }
    setForm(updatedForm);
  }

  const submitForm = () => {
    setSendingForm(true);
    Tokens().then((tokens) => {
    //   applicationService
    //   .createApplication(form, tokens.accessToken, tokens.idToken)
    //   .then(response => {
    //     console.log(response);
    //     setSendingForm(false);
    //     setSentError(false);
    //     setFormSent(true);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     setSendingForm(false);
    //     setSentError(true);
    //   })
    })
    .catch(error => {
        console.log(error);
      setSendingForm(false);
      setSentError(true);
    })
  }

  if(error.activeError && error.code !== 404) {
    return(
      <>
        <ErrorIcon color="error" fontSize="large"/>
        <Typography variant="h5"><b><TranslationContent contentID="loginFailed" /></b></Typography>
        <Typography variant="caption"><b>{error.code} - {error.body}</b></Typography>
        <br />
        {error.code === 403 && 
          <Typography mt="50px"><TranslationContent contentID="sessionExpired" /></Typography>
        }
      </>
    )
  }

  if(error.activeError && error.code === 404 && error.body === "E404A1"){
    return(
      <>
        <InfoIcon color="primary" fontSize="large"/>
        <Typography variant="h5" mt={2} mb={1}><b><TranslationContent contentID="hello" />, {activeAccount.name}!</b></Typography>
        <Typography variant="caption"><b><TranslationContent contentID="applicationPendingDesc" /></b></Typography>
        <br />
      </>
    )
  }

  if(error.activeError && error.code === 404 && error.body === "E404A2" && !formSent){
    return(
      <>
        <InfoIcon color="primary" fontSize="large"/>
        <Typography variant="h5" mt={2} mb={1}><b><TranslationContent contentID="" /><TranslationContent contentID="hello" />, {activeAccount.name}!</b></Typography>
        <Typography variant="caption"><b><TranslationContent contentID="applicationInitialStatusDesc" /></b></Typography>
        <Stack spacing={3} mt={5}>
          <TextField label={<TranslationContent contentID="organization" />} required onChange={event => handleFormInputChange(event, "requestedOrganization")} value={form.requestedOrganization} />
          <TextField label={<TranslationContent contentID="phoneNumber" />} onChange={event => handleFormInputChange(event, "phone")} value={form.phoneNumber} />
          <TextField multiline minRows={3} label={<TranslationContent contentID="additionalInformation" />} onChange={event => handleFormInputChange(event, "additionalInfo")} value={form.additionalInformation} />
          {sentError && 
            <Typography variant="caption" color="error"><TranslationContent contentID="sendingApplicationFailed" /></Typography>
          }
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="contained"
              fullWidth
              disabled={sendingForm || form.requestedOrganization === "" || form.requestedOrganization.length < 6}
              onClick={() => submitForm()}
            >
              <TranslationContent contentID="send" />
            </Button>
            {sendingForm && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Stack>
      </>
    )
  }

  if(error.activeError && error.code === 404 && error.body === "E404A3"){
    return(
      <>
        <InfoIcon color="primary" fontSize="large"/>
        <Typography variant="h5" mt={2} mb={1}><b><TranslationContent contentID="hello" />, {activeAccount.name}!</b></Typography>
        <Typography variant="caption"><b><TranslationContent contentID="rolesMissingDesc" /></b></Typography>
        <br />
      </>
    )
  }

  if(error.activeError && error.code === 404 && error.body === "E404A2" && formSent){
    return(
      <>
        <CheckCircleIcon color="success" fontSize="large"/>
        <Typography variant="h5" mt={2} mb={1}><b><TranslationContent contentID="applicationReceived" /></b></Typography>
        <Typography variant="caption"><b><TranslationContent contentID="applicationReceivedDesc" /></b></Typography>
        <br />
      </>
    )
  }
}

export default ErrorStatus;
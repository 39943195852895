function cloneProducts(products) {
  return products.map(product => JSON.parse(JSON.stringify(product)));
}

function setWarrantiesAndQualityClasses(products, qualityClass, aestheticWarranty, technicalWarranty) {
  const flashingLength = products.filter(p => p.name === 'Listat').length;

  return products.map(product => {
    if (flashingLength >= 1 && product.type === 'flashing') {
      product.quality_classes_name = qualityClass;
      product.technical_warranty_length = technicalWarranty;
      product.aesthetic_warranty_length = aestheticWarranty;
    }
    return product;
  });
}

function filterAndAddMissingProducts(products, productNames) {
  productNames.forEach((productName, index) => {
    const hasProductInArray = products.some(product => product.name === productName);

    if (!hasProductInArray) {
      products.push({
        id: products.length + index,
        material: "",
        name: productName,
        quality_classes_name: "",
        technical_warranty_length: "",
        aesthetic_warranty_length: "",
        type: "",
        comments: 'Tuote ei sisälly Ruukin toimitukseen'
      });
    }
  });

  return products;
}

function getOrderOfElement(element) {
  const nameParts = element.name.split(' ');
  const numberInName = parseInt(nameParts[nameParts.length - 1]) || 0;

  const orders = {
    'Ruukki Aluskate': 500,
    'Läpiviennit': 400,
    'Kattoturvatuotteet': 300,
    'Sadevesijärjestelmä': 200,
    'Listat': 100,
  };

  return orders[element.name] + numberInName;
}

function processResponse(response, productTypes, translations) {
  let tempProducts = [];

  productTypes.forEach((productType, index) => {
    const hasProductInArray = response?.data?.products.some(product => product.type === productType);

    if (!hasProductInArray) {
      tempProducts.push({
        id: tempProducts.length + index,
        material: "",
        name: translations['en'][productType],
        quality_classes_name: "",
        technical_warranty_length: "",
        aesthetic_warranty_length: "",
        type: productType,
        comments: 'Tuote ei sisälly Ruukin toimitukseen'
      });
    }
  });

  const tempRoofs = response?.data?.roofs.map((roof, idx) => {
    roof.id = idx + 1;
    roof.title = response?.data?.roofs.length > 0 ? `KATTOPROFIILI ${roof.id}` : 'KATTOPROFIILI';
    return roof;
  });

  const filteredRoofs = tempRoofs.reduce((acc, current) => {
    const el = acc.find(item =>
      item.quality_class === current.quality_class &&
      item.aesthetic_warranty_length === current.aesthetic_warranty_length &&
      item.technical_warranty_length === current.technical_warranty_length
    );

    if (!el) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  const groupedRoofs = filteredRoofs.map(r => {
    r.title = `Profiili (${r.quality_class})`;
    return r;
  });

  const clonedIndividualProducts = cloneProducts(response?.data?.products);
  const qualityClassFromFirstRoof = response?.data?.roofs[0]?.quality_class;
  const aestheticWarrantyLengthFromRoof = response?.data?.roofs[0]?.aesthetic_warranty_length;
  const technicalWarrantyLengthFromRoof = response?.data?.roofs[0]?.technical_warranty_length;

  // const tempProducts = setWarrantiesAndQualityClasses(clonedIndividualProducts, qualityClassFromFirstRoof, aestheticWarrantyLengthFromRoof, technicalWarrantyLengthFromRoof);
  const tempProductsUpdated = setWarrantiesAndQualityClasses(clonedIndividualProducts, qualityClassFromFirstRoof, aestheticWarrantyLengthFromRoof, technicalWarrantyLengthFromRoof);

  const filteredProducts = tempProductsUpdated.reduce((acc, current) => {
    const el = acc.find(item =>
      (item.type === current.type && item.type !== 'flashing') ||
      (item.type === 'flashing' &&
        item.aesthetic_warranty_length === current.aesthetic_warranty_length &&
        item.technical_warranty_length === current.technical_warranty_length)
    );

    if (!el) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  const possibleProducts = ['Listat', 'Sadevesijärjestelmä', 'Kattoturvatuotteet', 'Läpiviennit', 'Ruukki Aluskate'];
  const updatedProducts = filterAndAddMissingProducts(filteredProducts, possibleProducts);

  const sortedProducts = updatedProducts.sort((a, b) => getOrderOfElement(a) - getOrderOfElement(b));

  return {
    tempProducts: tempProductsUpdated,
    tempRoofs,
    groupedRoofs,
    sortedProducts
  };
}

// Export the processing function
export { processResponse };

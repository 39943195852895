import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LanguageIcon from '@mui/icons-material/Language';
import { Stack, Typography } from "@mui/material";
import { LanguageContext, LanguageDispatchContext } from "./LanguageProvider";
import TranslationContent from "./TranslationContent";

const SwitchLanguageButton = () => {
  const language = React.useContext(LanguageContext);
  const switchLanguage = React.useContext(LanguageDispatchContext);

    return(
      <>
        <FormControl  sx={{ m: 2, minWidth: 250 }} variant="standard">
          <InputLabel  id="demo-simple-select-autowidth-label" ><Stack  direction="row" spacing={1}><LanguageIcon color="primary" /><Typography><TranslationContent contentID="languageSelectLabel" /></Typography></Stack></InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={language.lang}
            //onChange={handleChange}
            autoWidth
            label="Finnish"
          >
            <MenuItem value='fi' onClick={() => switchLanguage({type: 'SWITCH_LANGUAGE_FIN'})}><Typography><TranslationContent contentID="finnish" /></Typography></MenuItem>
            <MenuItem value='en' onClick={() => switchLanguage({type: 'SWITCH_LANGUAGE_ENG'})}><Typography><TranslationContent contentID="english" /></Typography></MenuItem>
          </Select>
        </FormControl>
      </>
    )
}

export default SwitchLanguageButton;
import React from "react";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { BrightnessThemeDispatchContext, BrightnessThemeStateContext } from "./BrightnessThemeProvider";
import TranslationContent from "../Translations/TranslationContent";

const ToggleThemeButton = () => {
  const brightnessTheme = React.useContext(BrightnessThemeStateContext);
  const switchBrightnessTheme = React.useContext(BrightnessThemeDispatchContext);

  const handleChange = (event) => {
    if(event.target.value === "dark") 
      switchBrightnessTheme({type: 'SWITCH_THEME_DARK'})
    else 
      switchBrightnessTheme({type: 'SWITCH_THEME_LIGHT'})
  }

  return(
    <>
      <br />
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={brightnessTheme.theme}
        onChange={(event) => handleChange(event)}
        size="small"
      >
        <ToggleButton value="light" sx={{minWidth: "125px"}}><LightModeIcon />&nbsp;&nbsp;<TranslationContent contentID="lightModeButton" /></ToggleButton>
        <ToggleButton value="dark" sx={{minWidth: "125px"}}><DarkModeIcon />&nbsp;&nbsp;<TranslationContent contentID="darkModeButton" /></ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

export default ToggleThemeButton;
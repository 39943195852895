import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
} from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent";
import SearchBar from "../Utils/Components/SearchBar";
import RegistrationEditForm from "./Components/RegistrationEditForm";
import LoadingSpinner from "../Loading/LoadingSpinner";
import MainLayout from "../../Layouts/MainLayout";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { AuthContext } from "../../Context/AuthProvider";
import { Tokens } from "../../Services/tokens";
import registrationsService from "../../Services/registrations";
import { useViewport } from "../../Context/ViewportProvider";

const pageSize = 20;

const Registrations = () => {
  const [loading, setLoading] = useState(true);
  const [registrations, setRegistrations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { addAlert } = useContext(SnackBarContext);
  const auth = useContext(AuthContext);
  const { width } = useViewport();

  const fetchRegistrationsFromAPI = async (currentPage) => {
    try {
      const tokens = await Tokens();
      const response = await registrationsService.fetchRegistrations(
        {
          currentPage,
          pageSize,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      const { registrations: newRegistrations, total } = response.data;
      setRegistrations(newRegistrations);
      setTotalPages(Math.ceil(total / pageSize));
    } catch (error) {
      console.error("Failed to fetch registrations:", error);
      addAlert({ message: "errorFetchingData", type: "error" });
    }
  };

  const fetchRegistrations = async () => {
    setLoading(true);
    await fetchRegistrationsFromAPI(page);
    setLoading(false);
  };

  const deleteRegistration = async (id) => {
    setLoading(true);
    try {
      const tokens = await Tokens();
      await registrationsService.deleteRegistration(
        {
          id,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      fetchRegistrations();
      addAlert({ message: "deleteSuccess", type: "success" });
    } catch (error) {
      console.log(error);
      addAlert({ message: "errorDeleteFailed", type: "error" });
    }

    setLoading(false);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const searchRegistrations = async () => {
    setLoading(true);
    const tokens = await Tokens();
    const response = await registrationsService.searchRegistrations(
      {
        searchQuery,
        country: auth.country,
      },
      tokens.accessToken,
      tokens.idToken
    );

    const { registrations: newRegistrations } = response.data;
    setSearchResults(newRegistrations);
    setLoading(false);
  };

  useEffect(() => {
    if (!searchQuery) {
      setPage(1);
      setSearchResults([]);
      return;
    }

    const handler = setTimeout(() => {
      searchRegistrations();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    fetchRegistrations();
  }, [page]);

  return (
    <MainLayout>
      <Typography variant="h5" sx={{ marginBottom: "16px" }}>
        <TranslationContent contentID="registrations" />
      </Typography>
      <Paper
        sx={{
          padding: "32px",
          marginTop: "32px",
          "@media (max-width:900px)": {
            padding: "16px",
          },
        }}
      >
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TranslationContent contentID="customer" />
                    </TableCell>
                    {width > 600 && (
                      <TableCell>
                        <TranslationContent contentID="email" />
                      </TableCell>
                    )}
                    {width > 900 && (
                      <TableCell>
                        <TranslationContent contentID="deliveryDate" />
                      </TableCell>
                    )}
                    <TableCell>
                      <TranslationContent contentID="orderNumber" />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchResults.length > 0
                    ? searchResults
                    : registrations
                  ).map((registration) => (
                    <TableRow key={`${registration.id}`}>
                      <TableCell>{registration.customer}</TableCell>
                      {width > 600 && (
                        <TableCell>{registration.email}</TableCell>
                      )}
                      {width > 900 && (
                        <TableCell>
                          {registration.roofs[0].delivery_date}
                        </TableCell>
                      )}
                      <TableCell>{registration.vbeln}</TableCell>
                      <TableCell align="right">
                        <RegistrationEditForm
                          registration={registration}
                          fetchRegistrations={fetchRegistrations}
                          deleteRegistration={deleteRegistration}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {searchResults.length == 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handlePreviousPage} disabled={page <= 1}>
                  <TranslationContent contentID={"previous"} />
                </Button>
                <Typography
                  variant="body1"
                  sx={{ margin: "0 20px", lineHeight: "36px" }}
                >
                  {page} / {totalPages}
                </Typography>
                <Button onClick={handleNextPage} disabled={page >= totalPages}>
                  <TranslationContent contentID={"next"} />
                </Button>
              </div>
            )}
          </>
        )}
      </Paper>
    </MainLayout>
  );
};

export default Registrations;

import React from "react";
import RuukkiLogo from "../../../images/ruukki-header.png";
import PiskoLogo from "../../../images/Piristeel-avainlippu-logo-300x104.png";

const CompanyLogo = ({ width, showAppLogo }) => {
  return (
    <img
      src={RuukkiLogo}
      alt="Ruukki's orange Logo with text Ruukki"
      width={width}
      height="auto"
      style={{margin:"auto"}}
    />
  );
};

export default CompanyLogo;

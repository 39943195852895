import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent"

const ErrorComponent = ({ section }) => {
  return(
    <Paper sx={{padding: "16px", margin: "16px"}} elevation={5}>
      <Grid container>
        <Grid xs={12}>
          <Typography color="primary" variant="body2"><b><TranslationContent contentID={section} /></b></Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ErrorComponent;
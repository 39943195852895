import React, { useContext } from "react";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import {
  Drawer,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIconHandler from "./MenuIconHandler";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMsal } from "@azure/msal-react";
import TranslationContent from "../../Translations/TranslationContent";
import { useViewport } from "../../Context/ViewportProvider";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, mobileOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...((open || mobileOpen) && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerDesktop = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, mobileOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...((open || mobileOpen) && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

let navigationList = (country) => {
  if (country.toLowerCase() == "finland") {
    return {
      basic: [
        {
          section: "newRegistration",
          path: "/admin",
        },
        {
          section: "registrations",
          path: "/admin/registrations",
        },
        {
          section: "archive",
          path: "/admin/archive",
        },
        {
          section: "warrantyDetails",
          path: "/admin/warrantyDetails",
        },
      ],
    };
  } else {
    return {
      basic: [
        {
          section: "newRegistration",
          path: "/admin",
        },
        {
          section: "registrations",
          path: "/admin/registrations",
        },
        {
          section: "warrantyDetails",
          path: "/admin/warrantyDetails",
        },
      ],
    };
  }
};

const MainHeader = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const { instance } = useMsal();
  const { width } = useViewport();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  React.useEffect(() => {
    if (width < 750) {
      setOpen(false);
    }
  }, [width]);

  const handleDrawerOpen = () => {
    if (width > 750) {
      setOpen(true);
    } else {
      setMobileOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (width > 750) {
      setOpen(false);
    } else {
      setMobileOpen(false);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
    sessionStorage.clear();
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Stack spacing={0}>
            <Typography variant="h6" noWrap component="div" color="primary" sx={{fontSize: "14px"}}>
              <TranslationContent contentID="adminDashboard" />
            </Typography>
            <Typography
              variant="caption"
              component="div"
              color="primary"
              fontWeight="bold"
            >
              {<TranslationContent contentID={auth?.country}/>} – {auth.name} (<TranslationContent contentID={auth?.role}/>)
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      {width > 750 ? (
        <DrawerDesktop variant="permanent" open={open}>
          <DrawerHeader>
            <CompanyLogo width={150} showAppLogo={false} />
            <IconButton onClick={handleDrawerClose} color="primary">
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <Divider />

          <List>
            {navigationList(auth.country).basic.map((navigationItem, index) => (
              <ListItem
                key={navigationItem.section + index}
                disablePadding
                sx={{ display: "block" }}
                color="primary"
              >
                <Tooltip
                  title={
                    !open ? (
                      <TranslationContent contentID={navigationItem.section} />
                    ) : (
                      ""
                    )
                  }
                  arrow
                  placement="right"
                >
                  <ListItemButton
                    onClick={() => handleNavigation(navigationItem.path)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      color="primary"
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <MenuIconHandler navigationItem={navigationItem} />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <TranslationContent
                          contentID={navigationItem.section}
                        />
                      }
                      sx={{
                        opacity: open ? 1 : 0,
                        color:
                          location.pathname === navigationItem.path
                            ? window.location.hostname.includes("ruukki")
                              ? "#fc2516"
                              : "#009fe3"
                            : "",
                      }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>

          <Divider />

          <List>
            <ListItem
              key="logout-button-desktop"
              disablePadding
              sx={{ display: "block" }}
            >
              <Tooltip
                title={!open ? <TranslationContent contentID="logout" /> : ""}
                arrow
                placement="right"
              >
                <ListItemButton
                  color="primary"
                  onClick={handleLogout}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    color="primary"
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <LogoutIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<TranslationContent contentID="logout" />}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </DrawerDesktop>
      ) : (
        <Drawer
          open={mobileOpen}
          variant="temporary"
          sx={{ zIndex: 5000 }}
          onClose={handleDrawerClose}
        >
          <DrawerHeader>
            <CompanyLogo width={100} showAppLogo={false} />
            <IconButton onClick={handleDrawerClose} color="primary">
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <Divider />
          <List>
            {navigationList(auth.country).basic.map((navigationItem, index) => (
              <ListItem
                key={navigationItem.section + index}
                disablePadding
                sx={{ display: "block" }}
                color="primary"
              >
                <ListItemButton
                  onClick={() => handleNavigation(navigationItem.path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: mobileOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    color="primary"
                    sx={{
                      minWidth: 0,
                      mr: mobileOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <MenuIconHandler navigationItem={navigationItem} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <TranslationContent contentID={navigationItem.section} />
                    }
                    sx={{
                      opacity: mobileOpen ? 1 : 0,
                      color:
                        location.pathname === navigationItem.path
                          ? window.location.hostname.includes("ruukki")
                            ? "#fc2516"
                            : "#009fe3"
                          : "",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider />

          <List>
            <ListItem
              key="logout-button-mobile"
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                color="primary"
                onClick={handleLogout}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  color="primary"
                  sx={{
                    minWidth: 0,
                    mr: mobileOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LogoutIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<TranslationContent contentID="logout" />}
                  sx={{ opacity: mobileOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{ flexGrow: 1, p: width > 750 ? 3 : 1, mt: width > 750 ? 0 : 3 }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default MainHeader;

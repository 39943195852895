import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";
import { Paper, Button, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../Translations/TranslationContent";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import Loading from "../Loading/Loading";
import ErrorComponent from "../Error/ErrorComponent";
import FormTextField from "../Utils/Components/FormTextField";
import ReCAPTCHA from "react-google-recaptcha";
import { Tokens } from "../../Services/tokens";
import roofsService from "../../Services/roofs";
import { LanguageContext, LanguageDispatchContext } from "../../Translations/LanguageProvider";
import { BrightnessThemeStateContext } from "../../Theme/BrightnessThemeProvider";

const HomePage = () => {
  const language = useContext(LanguageContext);
  const switchLanguage = React.useContext(LanguageDispatchContext);
  const theme = useContext(BrightnessThemeStateContext);
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [additionalIdentifier, setAdditionalIdentifier] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const onReCAPTCHAChange = (captchaCode) => {
    if (captchaCode) {
      setRecaptchaToken(captchaCode);
    }
  };

  useEffect(() => {
    // Some problem with ReCAPTCHA, wont reset or scale
    if (!isAuthenticated && window.grecaptcha) {
      window.grecaptcha.reset();
    }
  }, [language.lang, theme.theme]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      let response;

      if (isAuthenticated) {
        const tokens = await Tokens();
        response = await roofsService.getRoofsAdmin(
          {
            knumv: additionalIdentifier,
            vbeln: orderNumber,
          },
          tokens.accessToken,
          tokens.idToken
        );
      } else {
        response = await roofsService.getRoofsUser(
          {
            knumv: additionalIdentifier,
            vbeln: orderNumber,
          },
          recaptchaToken
        );
      }

      const token = response.data;
      navigate(`/registration?token=${token}`);
    } catch (error) {
      setError(true);
      setErrorMessage("errorOccured");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && location.pathname === "/") {
      setLoading(true);
      navigate(`/roles`);
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const lang = searchParams.get("lang");

    if (lang) {
      switch (lang) {
        case 'fi':
          if (language.lang !== 'fi') {
            switchLanguage({type: 'SWITCH_LANGUAGE_FIN'});
          }
          break;
        case 'en':
          if (language.lang !== 'en') {
            switchLanguage({type: 'SWITCH_LANGUAGE_ENG'});
          }
          break;
        default:
          break;
      }
    }
  }, [location]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <MainLayout>
        <Paper
          sx={{
            maxWidth: "500px",
            margin: "auto",
            textAlign: "center",
            marginTop: "24px",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
        >
          <Typography variant="h5">
            <TranslationContent contentID="registrationInputHeader" />
          </Typography>
          {error && <ErrorComponent section={errorMessage} />}
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{ padding: "8px", marginTop: "24px" }}
            >
              <Grid xs={12}>
                <FormTextField
                  labelComponent="orderNumber"
                  value={orderNumber}
                  onChange={(event) => setOrderNumber(event.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid xs={12}>
                <FormTextField
                  labelComponent="additionalIdentifier"
                  value={additionalIdentifier}
                  onChange={(event) =>
                    setAdditionalIdentifier(event.target.value)
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  textAlign: "-webkit-center",
                }}
              >
                {!isAuthenticated && (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    theme={theme.theme}
                    hl={language.lang}
                    onChange={onReCAPTCHAChange}
                  />
                )}
              </Grid>
              <Grid xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "225px" }}
                  disabled={
                    !orderNumber ||
                    !additionalIdentifier ||
                    (!recaptchaToken && !isAuthenticated)
                  }
                >
                  <TranslationContent contentID="register" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </MainLayout>
    );
  }
};

export default HomePage;

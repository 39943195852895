export function areObjectsIdentical(obj1, obj2) {
    // Check for the same object reference or primitive value equality.
    if (obj1 === obj2) {
      return true;
    }
  
    // If both are null or not objects (function or primitive), they are not identical.
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }
  
    // Check if both parameters are instances of Date, and compare their time values.
    if (obj1 instanceof Date && obj2 instanceof Date) {
      return obj1.getTime() === obj2.getTime();
    }
  
    // If both are arrays, compare their lengths and recursively their elements.
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) {
        return false;
      }
      for (let i = 0; i < obj1.length; i++) {
        if (!areObjectsIdentical(obj1[i], obj2[i])) {
          return false;
        }
      }
      return true;
    }
  
    // Filter out keys that start with an underscore.
    const filterKeys = (keys) => keys.filter((key) => !key.startsWith("_"));
  
    // Get and filter keys of both objects.
    const keys1 = filterKeys(Object.keys(obj1));
    const keys2 = filterKeys(Object.keys(obj2));
  
    // Compare the lengths of filtered keys.
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    // Check if every filtered key in obj1 has an identical match in obj2, recursively.
    for (const key of keys1) {
      if (!keys2.includes(key) || !areObjectsIdentical(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
}

import React from "react";
import R30 from "../../../images/R30.png";
import R40 from "../../../images/R40.png";
import R50 from "../../../images/R50.png";

const WarrantyLogo = ({ value }) => {
  const imageMapping = {
    R30,
    R40,
    R50,
  };

  const selectedImage = imageMapping[value] || R50; // Default to R50 if value is not found

  return (
    <img src={selectedImage} alt="" width="100px" height="auto" style={{ textAlign: 'center' }}/>
  );
};

export default WarrantyLogo;

import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@mui/material";
import MainLayout from "../../Layouts/MainLayout";
import TranslationContent from "../../Translations/TranslationContent";
import LoadingSpinner from "../Loading/LoadingSpinner";
import WarrantyDefaults from "./Components/WarrantyDefaults";
import WarrantyTerms from "./Components/WarrantyTerms";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { areObjectsIdentical } from "../Utils/Helpers/utils";
import ErrorComponent from "../Error/ErrorComponent";
import { AuthContext } from "../../Context/AuthProvider";
import { Tokens } from "../../Services/tokens";
import warrantiesService from "../../Services/warranties";

const WarrantyDetails = () => {
  const [loading, setLoading] = useState(false);
  const [warrantyTerms, setWarrantyTerms] = useState({});
  const [warrantyTermsDraft, setWarrantyTermsDraft] = useState({});
  const [termsToPublish, setTermsToPublish] = useState(false);
  const [warrantyDefaults, setWarrantyDefaults] = useState({});
  const [termsLanguages, setTermsLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [error, setError] = useState(false);

  const auth = useContext(AuthContext);
  const { addAlert } = useContext(SnackBarContext);

  const fetchWarrantyDetails = async () => {
    setLoading(true);

    try {
      const tokens = await Tokens();
      const warrantyResponse = await warrantiesService.fetchWarranties(
        {
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      const defaults = warrantyResponse.data.warranties.defaults;
      const terms = warrantyResponse.data.warranties.terms;
      const draftTerms = warrantyResponse.data.drafts.terms;
      const termsMatch = areObjectsIdentical(terms, draftTerms);
      const languages = Object.keys(terms);

      setTermsLanguages(languages);
      setWarrantyTerms(terms);
      setWarrantyTermsDraft(draftTerms);
      setTermsToPublish(termsMatch);
      setWarrantyDefaults(defaults);
      if (!selectedLanguage) setSelectedLanguage(languages[0]);
    } catch (error) {
      setError(true);
      console.error("Error fetching warranty terms:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchWarrantyDetails();
  }, []);

  const handleTermAdd = (terms) => {
    setWarrantyTermsDraft(terms);
  };

  const handleTermsChange = async (terms) => {
    setLoading(true);
    setWarrantyTermsDraft(terms);

    try {
      const tokens = await Tokens();
      await warrantiesService.updateWarranties(
        {
          draft: true,
          terms: warrantyTermsDraft,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      addAlert({ message: "success", type: "success" });
    } catch (error) {
      addAlert({ message: "errorSavingFailed", type: "error" });
      console.error(error);
    }

    setLoading(false);
    await fetchWarrantyDetails();
  };

  const handlePublishTermChange = async () => {
    console.log("handlePublishTermChange");

    try {
      const tokens = await Tokens();
      await warrantiesService.updateWarranties(
        {
          draft: false,
          terms: warrantyTermsDraft,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      addAlert({ message: "success", type: "success" });
    } catch (error) {
      addAlert({ message: "errorSavingFailed", type: "error" });
      console.error(error);
    }

    setLoading(false);
    await fetchWarrantyDetails();
  };

  const handleDiscardTermDraft = async () => {
    setLoading(true);
    setWarrantyTermsDraft(warrantyTerms);

    try {

      const tokens = await Tokens();
      await warrantiesService.updateWarranties(
        {
          draft: true,
          terms: warrantyTerms,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      addAlert({ message: "snackbarPlaceholderText", type: "info" });
    } catch (error) {
      addAlert({ message: "errorSavingFailed", type: "error" });
      console.error(error);
    }

    setLoading(false);
    await fetchWarrantyDetails();
  };

  const handleQualityClassChange = (
    event,
    productIndex,
    qualityClassName,
    key
  ) => {
    const updatedProducts = warrantyDefaults.products.map((product, pIndex) => {
      if (productIndex === pIndex) {
        return {
          ...product,
          quality_classes: product.quality_classes.map((qc) => {
            if (qc.name === qualityClassName) {
              const value = event.target.value
                ? Number(event.target.value)
                : null;
              return {
                ...qc,
                [key]: value,
              };
            }
            return qc;
          }),
        };
      }
      return product;
    });

    setWarrantyDefaults({ ...warrantyDefaults, products: updatedProducts });
  };

  const handleLanguageChange = (event) => {
    console.log("selected language", event.target.value);
    setSelectedLanguage(event.target.value);
  };

  const handleDefaultsSave = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {

      const tokens = await Tokens();
      await warrantiesService.updateWarranties(
        {
          defaults: warrantyDefaults,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      addAlert({ message: "defaultsSaved", type: "success" });
    } catch (error) {
      addAlert({ message: "errorSavingFailed", type: "error" });
      console.error(error);
    }

    fetchWarrantyDetails();
  };

  const resetDefaults = async (event, defaults) => {
    event.preventDefault();
    setWarrantyDefaults(defaults);
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorComponent section={"errorTitle"} />
      ) : (
        <>
          <Typography variant="h5" sx={{ ml: 2 }}>
            <TranslationContent contentID="warrantyDefaults" />
          </Typography>
          <WarrantyDefaults
            warrantyDefaults={warrantyDefaults}
            handleQualityClassChange={handleQualityClassChange}
            handleDefaultsSave={handleDefaultsSave}
            resetDefaults={resetDefaults}
          />
          <Typography variant="h5" sx={{ ml: 2 }}>
            <TranslationContent contentID="warrantyTerms" />
          </Typography>
          <WarrantyTerms
            selectedLanguage={selectedLanguage}
            termsLanguages={termsLanguages}
            warrantyTerms={warrantyTermsDraft}
            termsToPublish={termsToPublish}
            handleTermAdd={handleTermAdd}
            handleDiscardTermDraft={handleDiscardTermDraft}
            handlePublishTermChange={handlePublishTermChange}
            handleLanguageChange={handleLanguageChange}
            onTermsSave={handleTermsChange}
          />
        </>
      )}
    </MainLayout>
  );
};

export default WarrantyDetails;

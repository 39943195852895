export const translations = {
  // ENGLISH TRANSLATIONS
  en: {
    // Project name
    projectName: "Roof registration",

    //Global buttons
    darkModeButton: "Dark",
    lightModeButton: "Light",
    finnish: "Suomi",
    english: "English",
    languageSelectLabel: "Language",
    logout: "Logout",
    home: "Home",
    newInspection: "New inspection",
    continueInspection: "Continue inspection",
    inspectionArchive: "Archive",
    account: "Account",
    userManagement: "User management",
    organizationManagement: "Organizations",
    siteManagement: "Sites",
    inspectionTemplate: "Inspection templates",
    reportTemplate: "Report templates",
    edit: "Edit",
    discardChanges: "Discard Changes",
    publish: "Publish",
    save: "Save",
    delete: "Delete",
    openPdf: "Open PDF",
    emailToCustomer: "Email PDF to customer",
    backToHome: "Back to homepage",
    saveAndPublish: "Save & publish",
    cancel: "Cancel",

    //Login component
    loginHeader: "Login",
    loginCaption: "Please sign in to continue.",
    loginFailed: "Login failed!",
    loginButton: "Login",

    //NOT FOUND 404
    notFoundCaption1: "OOPS! PAGE NOT FOUND",
    notFoundCaption2: "WE ARE SORRY TO INFORM YOU BUT THE PAGE YOU REQUESTED WAS NOT FOUND",
    notFoundTimer1: "REDIRECTING TO HOME PAGE IN",
    notFoundTimer2: "seconds",
    notFoundButton: "Back to home page",

    //Roles
    inspector: "Inspector",
    organizationAdmin: "Organization Admin",
    superAdmin: "Administrator",
    user: "User",
    welcomeback: "Welcome back",

    // ACCOUNT
    active: "Active",
    activate: "Acticate",

    // ERROR
    errorTitle: "An error occured!",
    errorDesc: "Fetching data failed",
    errorSalesOrderNotFound: "Sales order not found",
    errorRegistrationAlreadyExists: "Registration already exists",
    errorDecodingToken: "Failed to fetch the token",
    errorFetchingData: "Failed to fetch data",
    errorOccured: "Error occured. Please try again.",
    errorSavingFailed: "Failed to save changes",
    errorInvalidDate: "Invalid date",
    errorInvalidEmail: "Invalid email",

    //USER MANAGEMENT
    openApplicants: "Open applications",
    noOpenApplicants: "No open applications",
    acceptApplication: "Accept the application",
    rejectApplication: "Reject the application",
    pending: "Pending",
    accepting: "Accepting",
    rejecting: "Rejecting",
    accepted: "Accepted",
    rejected: "Rejected",
    sending: "Sending",
    processedApplication: "Application processed",
    noExtraInfo: "No additional information",

    //GLOBALS
    yes: "Yes",
    no: "No",
    manager: "Manager",
    admin: "Admin",
    logOutButton: "Logout",
    tryAgain: "Try again",
    success: "Success",

    // ROLES
    loginSuccessful: "Login successful!",
    loginSuccessDesc1: "Currently",
    loginSuccessDesc2: "account(s) signed in.",
    loginFailedDesc: "No accounts available.",
    sessionExpired: "",

    fetchingRoles: "Fetching role information...",
    selectAccount: "Select account",
    noGroupsAttachedErrorDesc: "There are no roles associated with the user account. To proceed to the application, contact your contact person and ensure that you have been assigned a role. Sometimes it may take a while for the rights to be updated, so we recommend trying again later.",
    chooseRole: "Choose a role",

    // GLOBALS

    // LANGUAGES
    fi: "Suomi",
    en: "English",

    // MESSAGES
    resettingValues: "Resetting values",
    
    // ROOF REGISTRATION
    registrationInputHeader: "Registration of roof guarantee",
    orderNumber: "Order number",
    additionalIdentifier: "Additional identifier",
    roof: "Roof",
    product: "Product",
    color: "Color",
    coating: "Coating",
    profile: "Profile",
    deliveryDate: "Delivery date",
    aestheticWarranty: "Aesthetic warranty",
    technicalWarranty: "Technical warranty",
    aestheticWarrantyEndDate: "Aesthetic warranty end date",
    technicalWarrantyEndDate: "Technical warranty end date",
    installation: "Installation",
    roofProducts: "Roof products",
    productNotIncluded: "Product is not included in Ruukki´s shipment",
    registrationCompleted: "Registration is completed!",
    registrationCompletedInfo: "Thank you for registrating. Your warranty certification has been send to your email.",
    manageRegistrations: "Manage registrations?", 

    orderInformation: "Order information",
    roofProfileInformation: "Roof profile information",
    warrantyInformation: "Warranty information",
    installationInformation: "Installation information",
    customerInformation: "Customer information",

    // FORM
    register: "Register",
    comment: "Comment",
    installDate: "Install date",
    mechanic: "Installation Firm / Installer",
    customerOrCondominium: "Customer / Condominium",
    customer: "Customer",
    phoneNumber: "Phone number",
    phone_number: "Phone number",
    email: "Email",
    address: "Address",
    zipCode: "Zip code",
    postalOffice: "Postal office",
    country: "Country",
    allowMarketing: "Allow marketing",

    // PRODUCT TYPES
    underlay: "Ruukki Underlay", 
    lead_through: "Lead though", 
    rsp: "Roof safety product",
    rws: "Rainwater system",
    flashing: "Flashing",

    // ADMIN
    adminDashboard: "Admin Dashboard - Roof Registration",
    newRegistration: "New registration",
    registrations: "Registrations",
    registrationArchiveInputHeader: "Registration archive",
    warrantyDetailsList: "Warranty details list",
    myAccountInputHeader: "My account",
    myAccount: "My account",
    analytics: "Analytics",
    status: "Status",
    marketingAllowed: "Marketing allowed",
    marketingNotAllowed: "Marketing not allowed",
    asennus_str: "Installation string",
    description: "Description",
    material: "Material",
    type: "Type",
    coating_type: "Coating type",
    coating_colour: "Coating colour",
    quality_class: "Quality class",
    quality_class_id: "Quality class ID",
    technical_warranty_length: "Technical warranty length",
    aesthetic_warranty_length: "Aesthetic warranty length",
    functional_warranty_length: "Functional warranty length",
    install_date: "Installation date",
    delivery_date: "Delivery date",
    name: "Name",
    mechanic_comment: "Mechanic comment",
    warrantyDetails: "Warranty details",
    archive: "Archive",
    registrationsArchive: "Registrations archive",
    registrationsArchiveInfo: "Archive includes all registrations made before 22.03.2024",
    registrationsNotFound: "Use search to find archived registrations",
    addLanguage: "Add language",
    addLanguageInstructions: "Please be sure when adding new languages for terms. Language will need to be added by using 2 character name. For example: english = en",
    editTermsSection: "Edit terms section",
    editRegistration: "Edit registartion",
    customerInfromation: "Customer information",
    additionalInfromation: "Additional information",
    products: "Products",

    warrantyTerms: "Warranty terms",
    warrantyDefaults: "Warranty defaults",

    // Confirmation
    confirm: "Confirm",
    delete_confirmation_title: "Delete registration?",
    delete_confirmation_text: "Deleting registration is permanent. Deleted information cannot be restored.",
    delete_term_confirmation_title: "Delete term",
    delete_term_confirmation_text: "You are about to delete term from draft. You can still discard draft chages, but you will lose all unpublished chages.",
  },

  // FINNISH TRANSLATIONS
  fi: {
    // Project name
    projectName: "Kattorekisteröinti",

    //Global buttons
    darkModeButton: "Tumma",
    lightModeButton: "Valoisa",
    finnish: "Suomi",
    english: "English",
    languageSelectLabel: "Kieli",
    login: "Kirjaudu",
    logout: "Kirjaudu ulos",
    home: "Koti",
    newInspection: "Uusi tarkastus",
    continueInspection: "Jatka tarkastusta",
    inspectionArchive: "Arkisto",
    account: "Käyttäjätili",
    userManagement: "Käyttäjien hallinta",
    organizationManagement: "Organisaatiot",
    siteManagement: "Kohteet",
    inspectionTemplate: "Tarkastuslomakkeet",
    reportTemplate: "Raporttipohjat",
    edit: "Muokkaa",
    discardChanges: "Hylkää muutokset",
    publish: "Julkaise",
    save: "Tallenna",
    delete: "Poista",
    openPdf: "Avaa PDF",
    emailToCustomer: "Lähetä PDF asiakkaalle",
    backToHome: "Takaisin etusivulle",
    saveAndPublish: "Tallenna & julkaise",

    //Login component
    loginHeader: "Kirjautuminen",
    loginCaption: "Ole hyvä ja kirjaudu sisään jatkaaksesi.",
    loginFailed: "Sisäänkirjautuminen epäonnistui!",
    loginButton: "Kirjaudu",

    //NOT FOUND 404
    notFoundCaption1: "OHO! SIVUA EI LÖYTYNYT",
    notFoundCaption2: "OLEMME PAHOILLAMME, MUTTA ETSIMÄÄNNE SIVUA EI LÖYTYNYT",
    notFoundTimer1: "OHJATAAN ETUSIVULLE",
    notFoundTimer2: "sekunnin kuluttua",
    notFoundButton: "Palaa takaisin etusivulle",

    //Roles
    inspector: "Tarkastaja",
    organizationAdmin: "Pääkäyttäjä",
    superAdmin: "Administraattori",
    user: "Käyttäjä",

    // ACCOUNT
    active: "Aktiivinen",
    activate: "Aktivoi",

    // DOWNLOAD DOCUMENT
    openedDesc1: "Kohteeseen",
    openedDesc2: "on tehty",
    openedDesc3: "",
    openedDesc4: "Tarkastuksen on toteuttanut",
    openedDesc5: "Voit ladata raportin alta.",
    downloadReport: "Lataa raportti",
    initialLinkTitle: "Tämä linkki tuhoaa itsensä!",
    initialLinkDesc: "Kun olet avannut linkin, et voi avata tätä raporttia uudelleen. Jos tarvitset pääsyn näihin tietoihin uudelleen, lataa raportti turvalliseen paikkaan.",
    openLink: "Avaa linkki",

    // ERROR
    errorTitle: "Tapahtui virhe!",
    errorDesc: "Tietojen haku epäonnistui",
    errorSalesOrderNotFound: "Tuotetta ei löydy.",
    errorRegistrationAlreadyExists: "Rekisteröinti on jo olemassa",
    errorDecodingToken: "Tokenin haku epäonnistui!",
    errorFetchingData: "Tietojen haku epäonnistui!",
    errorOccured: "Virhe tapahtui! Ole hyvä ja yritä uudestaan.",
    errorSavingFailed: "Tallennus epäonnistui!",
    errorInvalidDate: "Virheellinen päivämäärä",
    errorInvalidEmail: "Virheellinen sähköposti",

    //USER MANAGEMENT
    openApplicants: "Avoimet hakemukset",
    noOpenApplicants: "Ei avoimia hakemuksia",
    acceptApplication: "Hyväksy hakemus",
    rejectApplication: "Hylkää hakemus",
    pending: "Odottaa",
    accepting: "Hyväksyminen",
    rejecting: "Hylkääminen",
    accepted: "Hyväksytty",
    rejected: "Hylätty",
    sending: "Lähetetään",
    processedApplication: "Hakemus käsitelty",
    noExtraInfo: "Ei lisätietoja",

    // ROLES
    loginSuccessful: "Kirjautuminen onnistui!",
    loginSuccessDesc1: "Tällä hetkellä",
    loginSuccessDesc2: "käyttäjätunnusta on kirjautunut sisään.",
    loginFailedDesc: "Ei käyttäjätunnuksia saatavilla.",
    welcomeback: "Tervetuloa takaisin",

    fetchingRoles: "Haetaan käyttäjärooleja...",
    selectAccount: "Valitse käyttäjätunnus",
    noGroupsAttachedErrorDesc: "Käyttäjätunnukseen ei ole liitetty yhtään roolia. Jotta voit jatkaa sovellukseen, ole yhteydessä yhteyshenkilöösi ja varmista, että sinulle on annettu rooli. Joskus oikeuksien päivityksessä voi mennä hetki, joten suosittelemme yrittämään myöhemmin uudelleen.",
    chooseRole: "Valitse rooli",

    //GLOBALS
    yes: "Kyllä",
    no: "Ei",
    manager: "Pääkäyttäjä",
    admin: "Järjestelmänvalvoja",
    logOutButton: "Kirjaudu ulos",
    previous: "Edellinen",
    next: "Seuraava",
    success: "Onnistui",

    // LANGUAGES
    fi: "Suomi",
    en: "English",

    // MESSAGES
    resettingValues: "Muutokset nollattu",
    
    // ROOF REGISTRATION
    registrationInputHeader: "Ruukin kattotakuun rekisteröinti",
    orderNumber: "Tilausnumero",
    additionalIdentifier: "Lisätunniste",
    roof: "Katto",
    product: "Tuote",
    color: "Väri",
    coating: "Pinnoite",
    profile: "Profiili",
    deliveryDate: "Tuotantopäivä",
    aestheticWarranty: "Esteettinen takuu",
    technicalWarranty: "Tekninen takuu",
    aestheticWarrantyEndDate: "Esteettinen takuu päättyy",
    technicalWarrantyEndDate: "Tekninen takuu päättyy",
    installation: "Asennus",
    roofProducts: "Kattotuotteet",
    productNotIncluded: "Tuote ei sisälly Ruukin toimitukseen",
    registrationCompleted: "Rekisteröinti onnistunut!",
    registrationCompletedInfo: "Kiitos rekisteröinnistä. Takuutodistus toimitetaan sähköpostiin.",
    manageRegistrations: "Hallintapaneeli?", 

    orderInformation: "Tilaustiedot",
    roofProfileInformation: "Kattoprofiilintiedot",
    warrantyInformation: "Takuutiedot",
    installationInformation: "Asennustiedot",
    customerInformation: "Asiakastiedot",

    // FORM
    register: "Rekisteröi",
    comment: "Muita tietoja asennuksesta",
    installDate: "Asennusajankohta",
    mechanic: "Asennusliike / Asentaja",
    customerOrCondominium: "Asiakas / Asunto-osakeyhtiö",
    customer: "Asiakas",
    phoneNumber: "Puhelinnumero",
    phone_number: "Puhelinnumero",
    email: "Sähköposti",
    address: "Osoite",
    zipCode: "Postinumero",
    postalOffice: "Postitoimipaikka",
    country: "Maa",
    allowMarketing: "Ruukki voi lähettä minulle uutisia ja tarjouksia",

    // PRODUCT TYPES
    underlay: "Ruukki Aluskate", 
    lead_through: "Läpiviennit", 
    rsp: "Kattoturvatuotteet",
    rws: "Sadevesijärjestelmä",
    flashing: "Listat",

    // ADMIN
    adminDashboard: "Hallintapaneeli - Kattorekisteröinti",
    newRegistration: "Uusi rekisteröinti",
    registrations: "Rekisteröinnit",
    registrationArchiveInputHeader: "Arkisto",
    warrantyDetailsList: "Takuuehtolista",
    myAccountInputHeader: "Käyttäjäni",
    myAccount: "Käyttäjäni",
    analytics: "Analytiikka",
    status: "Status",
    marketingAllowed: "Markkinointi sallittu",
    marketingNotAllowed: "Ei markkinointia",
    asennus_str: "Asennus",
    description: "Kuvaus",
    material: "Materiaali",
    type: "Tyyppi",
    coating_type: "Pinnoite tyyppi",
    coating_colour: "Pinnoite väri",
    quality_class: "Laatuluokka",
    quality_class_id: "Laatuluokka tunniste",
    technical_warranty_length: "Teknisen takuun kesto",
    aesthetic_warranty_length: "Esteettisen takuun kesto",
    functional_warranty_length: "Toiminnallisen takuun kesto",
    install_date: "Asennuspäivämäärä",
    delivery_date: "Toimituspäivämäärä",
    name: "Nimi",
    mechanic_comment: "Asentajan huomio",
    warrantyDetails: "Takuuehdot",
    archive: "Arkisto",
    registrationsArchive: "Rekisteröinti arkisto",
    registrationsArchiveInfo: "Arkisto sisältää rekisteröinnin tehty ennen 22.03.2024",
    registrationsNotFound: "Käytä hakukenttää löytääksesi rekisteröintejä",
    editTermsSection: "Muokkaa ehtoja",

    editRegistration: "Muokkaa rekisteröintiä",
    customerInfromation: "Asiakkaan tiedot",
    additionalInfromation: "Lisätiedot",
    roofs: "Katot",
    products: "Tuotteet",

    warrantyTerms: "Takuuehdot",
    warrantyDefaults: "Takuuarvot",

    // Confirmation
    confirm: "Vahvista",
    cancel: "Peruuta",
    delete_confirmation_title: "Poista rekisteröinti?",
    delete_confirmation_text: "Rekisteröinnin poistaminen on pysyvä toimenpide. Poistettua tietoa ei voi palauttaa.",
    delete_term_confirmation_title: "Poista ehto",
    delete_term_confirmation_text: "Olet poistamassa ehtoa luonnoksesta. Voit edelleen palauttaa tiedot hylkäämällä muutokset, mutta menetät kaikki julkaisemattomat muutokset",
  }

}
import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
} from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent";
import SearchBar from "../Utils/Components/SearchBar";
import LoadingSpinner from "../Loading/LoadingSpinner";
import MainLayout from "../../Layouts/MainLayout";
import { Tokens } from "../../Services/tokens";
import registrationsService from "../../Services/registrations";
import { AuthContext } from "../../Context/AuthProvider";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import pdfService from "../../Services/pdf";
import { useViewport } from "../../Context/ViewportProvider";

const RegistrationsArchive = () => {
  const [loading, setLoading] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const auth = useContext(AuthContext);
  const { addAlert } = useContext(SnackBarContext);
  const { width } = useViewport();

  const openPDF = async (pdfId) => {
    try {
      const tokens = await Tokens();
      const response = await pdfService.archivePDF(
        {
          pdfId,
        },
        tokens.accessToken,
        tokens.idToken
      );

      const byteCharacters = atob(response.data);
      const byteNumbers = byteCharacters
        .split("")
        .map((char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    } catch (e) {
      console.error("Error in openPDF: ", e);
    }
  };

  const searchRegistrations = async () => {
    setLoading(true);
   
    try {
      const tokens = await Tokens();
      const response = await registrationsService.archiveSearchRegistrations(
        {
          searchQuery,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );
  
      const { registrations: newRegistrations } = response.data;
      setRegistrations(newRegistrations);
    } catch (error) {
      addAlert({ message: "errorFetchingData", type: "error" });
    }


    setLoading(false);
  };

  useEffect(() => {
    if (!searchQuery) {
      setRegistrations([]);
      return;
    }

    const handler = setTimeout(() => {
      searchRegistrations();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  return (
    <MainLayout>
      <Typography variant="h5">
        <TranslationContent contentID="registrationsArchive" />
      </Typography>
      <Typography variant="caption">
        <TranslationContent contentID="registrationsArchiveInfo" />
      </Typography>
      <Paper sx={{ padding: "32px", marginTop: "32px" }}>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {registrations.length == 0 ? (
              <Typography
                variant="subtitle1"
                sx={{ marginTop: "32px", textAlign: "center" }}
              >
                <TranslationContent contentID="registrationsNotFound" />
              </Typography>
            ) : (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Customer</TableCell>
                      {width > 600 && <TableCell>Email</TableCell>}
                      {width > 900 && <TableCell>Delivery date</TableCell>}
                      <TableCell>Order number</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registrations.map((registration) => (
                      <TableRow key={`${registration.id}`}>
                        <TableCell>{registration.customer}</TableCell>
                        {width > 600 && (
                          <TableCell>{registration.email}</TableCell>
                        )}
                        {width > 900 && (
                          <TableCell>{registration.deliveryDate}</TableCell>
                        )}
                        <TableCell>{registration.vbeln}</TableCell>
                        <TableCell align="right">
                          <Button onClick={() => openPDF(registration.pdf.key)}>
                            <TranslationContent contentID="PDF" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Paper>
    </MainLayout>
  );
};

export default RegistrationsArchive;

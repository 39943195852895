import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Stack } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";

const LandingHeader = () => {
  return (
    <AppBar
      position="static"
      sx={{ minWidth: "100vw", minWidth: "298px", maxWidth: "100vw" }}
    >
      <Container maxWidth="100%">
        <Toolbar disableGutters>
          <MainTitle />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default LandingHeader;

const MainTitle = () => {
  const { width } = useViewport();
  if (width < 300) {
    return (
      <Stack direction="row" spacing={2} sx={{ padding: "5px" }}>
        <CompanyLogo width={155} showAppLogo={true} />
      </Stack>
    );
  } else {
    return (
      <Stack direction="row" spacing={2} sx={{ padding: "5px" }}>
        <CompanyLogo width={155} showAppLogo={true} />
      </Stack>
    );
  }
};

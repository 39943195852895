import React, { useState, useContext } from "react";
import { Box, Button } from "@mui/material";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import LogoutIcon from "@mui/icons-material/Logout";
import TranslationContent from "../../Translations/TranslationContent";
import { msalInstance } from "../..";
import { Tokens } from "../../Services/tokens";
import { AuthContext, AuthDispatchContext } from "../../Context/AuthProvider";
import roleService from "../../Services/roles";
import RoleStatus from "./RoleStatus";
import AccountSelector from "./AccountSelector";
import LoginStatus from "./LoginStatus";
import ErrorStatus from "./ErrorStatus";

function formatString(input) {
  const spacesString = input.replace(/\./g, ' ');
  const capitalizedString = spacesString.replace(/\b\w/g, (char) => char.toUpperCase());
  return capitalizedString;
}

const Roles = () => {
  const { instance, accounts } = useMsal();
  const [activeAccount, setActiveAccount] = React.useState({});
  const [activeAccountSelected, setActiveAccountSelected] =
    React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [stateError, setStateError] = React.useState({
    activeError: false,
    code: "",
    body: "",
  });
  const auth = useContext(AuthContext);
  const updateAuth = useContext(AuthDispatchContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
    sessionStorage.clear();
  };

  const fetchRoles = (account) => {
    Tokens()
      .then((tokens) => {
        roleService
          .getRole(tokens.accessToken, tokens.idToken)
          .then((response) => {
            setRoles(response);
            if (response.length === 1) {
              updateActiveRole(response[0])
            }
            if (response.length === 0) {
              setRoles(response);
              let newError = { ...stateError };
              newError.code = "E404";
              setStateError(newError);
            }
          })
          .catch((error) => {
            let newError = { ...stateError };
            newError.activeError = true;
            newError.code = error.response?.status;
            newError.body = error.response?.statusText;
            setStateError(newError);
          });
      })
      .catch((error) => {
        let newError = { ...stateError };
        newError.activeError = true;
        newError.code = error.response?.status;
        newError.body = error.response?.statusText;
        setStateError(newError);
      });
  };


  const updateActiveAccount = (account) => {
    let updatedAccount = { ...activeAccount };
    updatedAccount = account;
    setActiveAccount(updatedAccount);
    setActiveAccountSelected(true);
    msalInstance.setActiveAccount(account);
  };

  const updateActiveRole = async (role) => {
    const response = await Tokens()
    const name = formatString(response.name)
    const updatedAuth = { ...auth };
    updatedAuth.country = role.country;
    updatedAuth.company = role.company;
    updatedAuth.role = role.role;
    updatedAuth.name = name;
    updateAuth({ type: "UPDATE_CREDENTIALS", value: updatedAuth });
    navigate("/admin");
  };

  const fetchData = React.useRef(true);

  React.useEffect(() => {
    if (accounts.length === 1 && fetchData.current) {
      fetchData.current = false;
      msalInstance.setActiveAccount(accounts[0]);
      setActiveAccount(accounts[0]);
      setActiveAccountSelected(true);
      fetchRoles(accounts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (accounts.length < 1) return <NotFound />;

  if (accounts.length > 0) {
    return (
      <Box
        width={"100vw"}
        height={"100vh"}
        sx={{ padding: "5% 5% 5% 5%", textAlign: "center" }}
      >
        <Box mb={"60px"}>
          <CompanyLogo showAppLogo={true} width={270} />
        </Box>
        <ErrorStatus error={stateError} activeAccount={activeAccount} />
        <LoginStatus
          accounts={accounts}
          activeAccount={activeAccount}
          activeAccountSelected={activeAccountSelected}
          error={stateError.activeError}
        />
        <RoleStatus
          activeAccount={activeAccount}
          activeAccountSelected={activeAccountSelected}
          roles={roles}
          error={stateError}
          updateActiveRole={updateActiveRole}
        />
        <AccountSelector
          accounts={accounts}
          activeAccountSelected={activeAccountSelected}
          updateActiveAccount={updateActiveAccount}
          error={stateError.activeError}
        />
        <br />
        <Button
          variant="outlined"
          size="small"
          sx={{ marginTop: "25px" }}
          onClick={() => handleLogout()}
        >
          <LogoutIcon fontSize="small" />
          &nbsp;&nbsp;
          <TranslationContent contentID="logOutButton" />
        </Button>
      </Box>
    );
  }
};

export default Roles;
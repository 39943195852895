import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import AuthenticatedLayout from "./AuthenticatedLayout";
import UnauthenticatedLayout from "./UnauthenticatedLayout";

const MainLayout = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  const layout = isAuthenticated ? (
    <AuthenticatedLayout>{children}</AuthenticatedLayout>
  ) : (
    <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
  );
  return layout;
};

export default MainLayout;

import React from "react";
import { Typography, Grid } from "@mui/material";
import WarrantyTermsEditForm from "./WarrantyTermsEditForm";

const TermSection = ({
  sectionKey,
  termKey,
  term,
  onTermSave,
  onTermDelete,
}) => {
  return (
    <>
      <Grid
        xs={12}
        container
        item
        alignItems="center"
        spacing={2}
        sx={{ alignItems: "flex-start" }}
      >
        <Grid item>
          <Typography variant="subtitle1" component="span" fontWeight="bold">
            {termKey}.
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1">{term.text}</Typography>
        </Grid>
        <Grid item>
          <WarrantyTermsEditForm
            sectionKey={sectionKey}
            termKey={termKey}
            term={term}
            onTermSave={onTermSave}
            onTermDelete={onTermDelete}
          />
        </Grid>
      </Grid>
      {term.options &&
        Object.entries(term.options).map(([optionKey, optionValue]) => (
          <Grid
            item
            xs={12}
            key={optionKey}
            container
            spacing={2}
            sx={{ ml: 4 }}
          >
            <Grid item>
              <Typography variant="subtitle2" component="span">
                {optionKey}.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">{optionValue}</Typography>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default TermSection;

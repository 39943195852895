import React from "react";
import { Paper, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ErrorIcon from "@mui/icons-material/Error";
import ReplayIcon from "@mui/icons-material/Replay";
import TranslationContent from "../../Translations/TranslationContent";
import ErrorComponent from "./ErrorComponent";

const Error = ({ section, retryClick }) => {
  return (
    <Paper sx={{ padding: "7px", width: "100%", marginTop: "32px" }}>
      <Grid container spacing={2} mt={0}>
        <Grid
          xs={12}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            padding: "50px",
          }}
        >
          <ErrorIcon sx={{ width: 40, height: 40 }} color="error" />
          <Typography>
            <b>
              <TranslationContent contentID={section} />
            </b>
          </Typography>
          <div style={{ height: "25px" }} />
          <Button onClick={() => retryClick()} size="small" variant="contained">
            <TranslationContent contentID={"tryAgain"} />
            <ReplayIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Error;

import React from "react";
import {
  Typography,
  Paper,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TranslationContent from "../../../Translations/TranslationContent";
import TermSection from "./TermSection";

const WarrantyTerms = ({
  selectedLanguage,
  termsLanguages,
  warrantyTerms,
  termsToPublish,
  handleTermAdd,
  handleDiscardTermDraft,
  handlePublishTermChange,
  handleLanguageChange,
  onTermsSave,
}) => {
  const handleTermsSave = (sectionKey, termKey, editedTerm) => {
    const newTerms = { ...warrantyTerms };
    newTerms[selectedLanguage][sectionKey][termKey] = editedTerm;
    onTermsSave(newTerms);
  };

  const onTermDelete = (sectionKey, termKey) => {
    console.log("*** onTermDelete", sectionKey, termKey);

    const newTerms = { ...warrantyTerms };
    delete newTerms[selectedLanguage][sectionKey][termKey];

    // Reorder keys
    const sectionTerms = newTerms[selectedLanguage][sectionKey];
    const orderedKeys = Object.keys(sectionTerms).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );
    const reorderedTerms = {};
    orderedKeys.forEach((key, index) => {
      // Reassign keys starting from 1 to maintain sequential order
      reorderedTerms[index + 1] = sectionTerms[key];
    });

    newTerms[selectedLanguage][sectionKey] = reorderedTerms;
    onTermsSave(newTerms);
  };

  const onTermAdd = (sectionKey) => {
    const languageSection = warrantyTerms[selectedLanguage][sectionKey] || {};
    const nextKey =
      Object.keys(languageSection).reduce(
        (maxKey, currentKey) => Math.max(maxKey, parseInt(currentKey)),
        0
      ) + 1;

    const newTerms = {
      ...warrantyTerms,
      [selectedLanguage]: {
        ...warrantyTerms[selectedLanguage],
        [sectionKey]: {
          ...languageSection,
          [nextKey]: { text: "" },
        },
      },
    };

    console.log("newTerms", newTerms);
    handleTermAdd(newTerms)
  };

  return (
    <Paper sx={{ margin: "16px", padding: "16px" }}>
      <Grid
        container
        sx={{
          padding: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item>
          <FormControl>
            <Select
              value={selectedLanguage || ""}
              onChange={handleLanguageChange}
              displayEmpty
              sx={{ width: "100px" }}
            >
              {termsLanguages.map((language) => (
                <MenuItem value={language} key={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Grid item>
            <Button
              onClick={handleDiscardTermDraft}
              variant="contained"
              disabled={termsToPublish}
              sx={{marginRight: "16px"}}
            >
              <Typography variant="subtitle2">
                <TranslationContent contentID={"discardChanges"} />
              </Typography>
            </Button>
            <Button
              onClick={handlePublishTermChange}
              variant="contained"
              disabled={termsToPublish}
            >
              <Typography variant="subtitle2">
                <TranslationContent contentID={"publish"} />
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {warrantyTerms[selectedLanguage] &&
        Object.entries(warrantyTerms[selectedLanguage]).map(([key, value]) => (
          <Grid
            container
            spacing={2}
            key={key}
            sx={{ marginBottom: "32px", padding: "16px" }}
          >
            <Grid item xs={12}>
              <Typography variant="h4">
                <TranslationContent contentID={key} />
              </Typography>
            </Grid>
            {Object.entries(value).map(([termKey, term]) => (
              <TermSection
                sectionKey={key}
                key={termKey}
                termKey={termKey}
                term={term}
                onTermSave={handleTermsSave}
                onTermDelete={onTermDelete}
              />
            ))}
            <Grid
              xs={12}
              container
              item
              alignItems="center"
              spacing={2}
              sx={{ alignItems: "flex-start" }}
            >
              <Grid item>
                <Typography
                  variant="subtitle1"
                  component="span"
                  fontWeight="bold"
                >
                  {`${
                    Object.keys(warrantyTerms[selectedLanguage][key]).length + 1
                  }.`}
                </Typography>
              </Grid>

              <Grid item xs>
                <Button
                  onClick={() => onTermAdd(key)}
                  variant="contained"
                  sx={{
                    background: "none",
                    padding: "0",
                    margin: "0",
                    minWidth: "0",
                  }}
                >
                  <AddIcon color="primary" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Paper>
  );
};

export default WarrantyTerms;

import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import ModalContainer from "../../Utils/Components/ModalContainer";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TranslationContent from "../../../Translations/TranslationContent";
import ConfirmDialog from "../../Utils/Components/ConfirmDialog";

const WarrantyTermsEditForm = ({
  sectionKey,
  termKey,
  term,
  onTermSave,
  onTermDelete,
}) => {
  const [editedTerm, setEditedTerm] = useState(term);
  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    setEditedTerm(term);
  }, [term]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditedTerm(term);
  };

  const handleSave = () => {
    onTermSave(sectionKey, termKey, editedTerm);
    setOpen(false);
  };

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };

  const handleTermChange = (event) => {
    setEditedTerm({ ...editedTerm, text: event.target.value });
  };

  const handleOptionChange = (event, key) => {
    const optionValue = event.target.value;
    const updatedOptions = { ...editedTerm.options, [key]: optionValue };
    setEditedTerm({ ...editedTerm, options: updatedOptions });
  };

  const handleAddOption = () => {
    const options = editedTerm?.options ? { ...editedTerm.options } : {};
    const keys = Object.keys(options);
    if (keys.length === 0 || keys[keys.length - 1] !== "z") {
      const nextKey =
        keys.length === 0
          ? "a"
          : String.fromCharCode(keys[keys.length - 1].charCodeAt(0) + 1);
      options[nextKey] = "";
    }

    setEditedTerm({ ...editedTerm, options: options });
  };

  const handleRemoveOption = (key) => {
    let options = { ...editedTerm.options };
    if (options.hasOwnProperty(key)) {
      delete options[key];

      const keys = Object.keys(options).sort();
      const newOptions = {};
      let currentChar = "a";

      for (const oldKey of keys) {
        newOptions[currentChar] = options[oldKey];
        currentChar = String.fromCharCode(currentChar.charCodeAt(0) + 1);
      }

      setEditedTerm({ ...editedTerm, options: newOptions });
    } else {
      console.log(`Key '${key}' not found in options.`);
    }
  };

  if (showDeleteDialog) {
    return (
      <ConfirmDialog
        open={showDeleteDialog}
        title={
          <TranslationContent contentID={"delete_term_confirmation_title"} />
        }
        text={
          <TranslationContent contentID={"delete_term_confirmation_text"} />
        }
        handleClose={() => setShowDeleteDialog(false)}
        confirmClick={() => {
          onTermDelete(sectionKey, termKey);
          setOpen(false);
        }}
      />
    );
  } else {
    return (
      <ModalContainer open={open} handleClose={handleClose} onEdit={handleOpen}>
        <Typography variant="h5"><TranslationContent contentID={"editTermsSection"} /></Typography>
        <Grid
          container
          sx={{
            padding: "8px",
          }}
        >
          <Grid item>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              {termKey}.
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ flex: 1, marginRight: "40px", mb: 2, marginLeft: "16px" }}
          >
            <TextField
              multiline
              value={editedTerm.text}
              onChange={handleTermChange}
              placeholder="Enter text here..."
              fullWidth
              InputProps={{
                style: {
                  overflow: "auto",
                  marginRight: "24px",
                  padding: "8px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "1px red solid",
                },
              }}
            />
          </Grid>
          {editedTerm.options &&
            Object.entries(editedTerm.options).map(
              ([optionKey, optionValue]) => (
                <Grid
                  item
                  xs={12}
                  key={optionKey}
                  container
                  sx={{ ml: 4, mb: 2 }}
                >
                  <Grid item>
                    <Typography variant="subtitle2" component="span">
                      {optionKey}.
                    </Typography>
                  </Grid>
                  <Grid item xs sx={{ margin: "0 16px" }}>
                    <TextField
                      multiline
                      value={optionValue}
                      onChange={(event) => handleOptionChange(event, optionKey)}
                      placeholder="Enter text here..."
                      fullWidth
                      InputProps={{
                        style: {
                          maxHeight: "300px",
                          overflow: "auto",
                          padding: "8px",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px red solid",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ width: "24px" }}>
                    <Button
                      onClick={() => handleRemoveOption(optionKey)}
                      variant="contained"
                      sx={{
                        background: "none",
                        padding: "0",
                        margin: "0",
                        minWidth: "0",
                      }}
                    >
                      <DeleteIcon color="primary" />
                    </Button>
                  </Grid>
                </Grid>
              )
            )}
          <Grid item xs={12} container sx={{ ml: 4 }}>
            <Grid item>
              <Button
                onClick={handleAddOption}
                variant="contained"
                sx={{
                  background: "none",
                  padding: "0",
                  margin: "0",
                  minWidth: "0",
                }}
              >
                <AddIcon color="primary" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            padding: "8px",
          }}
        >
          <Grid item>
            <Button onClick={handleSave}>
              <Typography variant="subtitle2" component="span">
                <TranslationContent contentID={"save"} />
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleDelete}>
              <Typography variant="subtitle2" component="span">
                <TranslationContent contentID={"delete"} />
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </ModalContainer>
    );
  }
};

export default WarrantyTermsEditForm;

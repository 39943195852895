import axios from "axios";
import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

const apiBaseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_API_KEY;

export async function Tokens(accessToken) {
  let tokens = {
    accessToken: String(),
    idToken: String(),
    userId: String(),
    tokens: new Array(),
  };

  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    tokens.accessToken = response.accessToken;
    tokens.idToken = response.idToken;
    tokens.userId = response.idTokenClaims.oid;
    tokens.groups = response.idTokenClaims.groups;
    tokens.name = response.account.name
  }
  return tokens;
}

const verifyToken = (data) => {
  const request = axios.post(
    `${apiBaseURL}/verifyToken`,
    {
      token: data.token,
    },
    {
      headers: {
        "x-api-key": apiKey,
      },
    }
  );
  
  return request;
};

export default { verifyToken };

import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_API_KEY;

const fetchRegistrations = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/registrations?page=${data.currentPage}&pageSize=${data.pageSize}&country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const searchRegistrations = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/registrations?search=${data.searchQuery}&country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

// For unauthenticated users
const createRegistration = async (data) => {
  const response = await axios.post(
    `${apiBaseURL}/createRegistration`,
    {
      ...data
    },
    {
      headers: {
        "x-api-key": apiKey,
      },
    }
  );

  return response;
};

const updateRegistration = async (data, accessToken, idToken) => {
  const response = await axios.put(
    `${apiBaseURL}/registrations?country=${data.country}`,
    {
      ...data
    },
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const deleteRegistration = async (data, accessToken, idToken) => {
  const response = await axios.delete(
    `${apiBaseURL}/registrations?id=${data.id}&country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
}; 

const archiveSearchRegistrations = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/registrationsArchive?search=${data.searchQuery}&country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};
const registrationsServices = {
  fetchRegistrations,
  searchRegistrations,
  createRegistration,
  updateRegistration,
  deleteRegistration,
  archiveSearchRegistrations
};
export default registrationsServices;

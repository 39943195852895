import RuukkiFavicon from "../src/images/faviconRuukki.ico";

export function appInitialization() {
  const favicon = getFaviconEl();
  const faviconApple = geAppletFaviconEl();
  favicon.href = RuukkiFavicon;
  faviconApple.href = RuukkiFavicon;

  console.log(`
######  #     # #     # #    # #    # ### 
#     # #     # #     # #   #  #   #   #  
#     # #     # #     # #  #   #  #    #  
######  #     # #     # ###    ###     #  
#   #   #     # #     # #  #   #  #    #  
#    #  #     # #     # #   #  #   #   #  
#     #  #####   #####  #    # #    # ### `);
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

function geAppletFaviconEl() {
  return document.getElementById("apple-favicon");
}

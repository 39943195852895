import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_API_KEY;

const getRole = (accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/roles`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "x-id-token": "Bearer " + idToken,
      "x-api-key": apiKey,
    },
  });
  return request.then((response) => response.data);
};

export default { getRole };

import React from "react"

export const AuthContext = React.createContext()
export const AuthDispatchContext = React.createContext()

//If you want to debug only frontend without BE and db, uncomment this
//const initialProductCatalog = devProductCatalog;
//And comment this
const initialCredentials = {
  name: "",
  role: "", // admin, employee, customer
  accessToken: "",
  idToken: "",
  expiresOn: Date(),
  country: "",
  company: "",
  location: "",
};

function authReducer(credentials, action) {
  switch(action.type) {
    case "UPDATE_CREDENTIALS": {
      //console.log(action.value);
      let credentialsCopy = {...credentials};
      credentialsCopy = action.value;
      //productCatalogCopy.targetInfo.workNumber.value = action.value;
      //productCatalogCopy.targetInfo.workNumber.isValid = workNumberValid;
      //console.log(credentialsCopy);
      sessionStorage.setItem("roles", JSON.stringify(credentialsCopy));

        return {
          //...credentials,
          ...credentialsCopy,
        }
    }
    case "REMOVE_CREDENTIALS": {
      sessionStorage.clear();
      let credentialsCopy = {...credentials};
      credentialsCopy.name = "";
      credentialsCopy.role = "";
      credentialsCopy.accessToken = "";
      credentialsCopy.idToken = "";
      credentialsCopy.country = "";
      return {
        ...credentials,
        ...credentialsCopy,
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const checkSessionStorageRoles = (initialState)  => {
  //console.log(JSON.parse(sessionStorage.getItem("roles")));
  const sessionRoles = JSON.parse(sessionStorage.getItem("roles"));
  //console.log(sessionRoles)
  if(sessionRoles !== null) {
    initialState = sessionRoles;
    return sessionRoles;
  }
  else return initialState;
}

const AuthProvider = ({ children }) => {
  const [credentials, dispatch ] = React.useReducer(authReducer, initialCredentials, checkSessionStorageRoles)

  return(
    <AuthContext.Provider value={credentials}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  )
}

export default AuthProvider;

import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_API_KEY;

const fetchPDF = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/pdf/${data.pdfId}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const archivePDF = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/pdfArchive?path=${data.pdfId}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};
const registrationsServices = {
  fetchPDF,
  archivePDF,
};
export default registrationsServices;

import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TextField } from "@mui/material";

dayjs.extend(customParseFormat);

const CalendarPicker = ({ date, setDate, props }) => {

  const handleSet = (value) => {
    const formattedDate = value ? dayjs(value).format("DD-MM-YYYY") : null; 
    if (props) {
      setDate(props, formattedDate);
    } else {
      setDate(formattedDate);
    }
  }

  const parseDate = (dateString) => {
    return dateString ? dayjs(dateString, "DD-MM-YYYY") : null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fi"}>
      <DatePicker
        sx={{
          width: "100%",
          "& input": {
            padding: "8.5px 14px",
            height: "1.4375em",
          },
        }}
        disableFuture
        value={parseDate(date)} 
        onChange={(value) => handleSet(value)}
        format="DD/MM/YYYY"
        renderInput={(params) => <TextField {...params} required />}
      />
    </LocalizationProvider>
  );
};

export default CalendarPicker;

import { useContext } from "react";
import { LanguageContext } from "./LanguageProvider";
import { translations } from "./translations.js";

/**
 * @param {React Component props} props destructured just for the contentID
 * @returns
 */
export default function TranslationContent({ contentID }) {
  const language  = useContext(LanguageContext);
  const translation = translations[language.lang][contentID]
  return translation ? translation : contentID;
}
import React from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";
import { Paper, Button, Typography } from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent";

const RegistrationCompletedPage = () => {
  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/");
  };
  
  return (
    <MainLayout>
      <Paper
        sx={{
          maxWidth: "500px",
          margin: "auto",
          textAlign: "center",
          marginTop: "24px",
          padding: "24px",
        }}
      >
        <Typography variant="h5">
          <TranslationContent contentID="registrationCompleted" />
        </Typography>
        <Typography variant="subtitle1" sx={{ margin: "24px 0" }}>
          <TranslationContent contentID="registrationCompletedInfo" />
        </Typography>
        <Button onClick={() => backToHome()}>
          <TranslationContent contentID="backToHome" />
        </Button>
      </Paper>
    </MainLayout>
  );
};

export default RegistrationCompletedPage;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import { useViewport } from "../../../Context/ViewportProvider";

const buttonStyle = {
  width: "225px",
  margin: "0px 16px 16px 16px",
};

const headingStyle = {
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  hyphens: 'auto',
  "@media (max-width:600px)": {
    fontSize: "12px"
  },
}

const WarrantyDefaults = ({
  warrantyDefaults,
  handleQualityClassChange,
  handleDefaultsSave,
  resetDefaults,
}) => {
  const [edit, setEdit] = useState(false);
  const [originalWarrantyDefaults, setOriginalWarrantyDefaults] = useState([]);
  const { width } = useViewport();

  useEffect(() => {
    setOriginalWarrantyDefaults(warrantyDefaults);
  }, []);

  const handleEditButtonClick = (event) => {
    if (edit) {
      resetDefaults(event, originalWarrantyDefaults);
    }

    setEdit(!edit);
  };

  return (
    <Paper
      sx={{
        margin: "16px",
        padding: "16px",
        "@media (max-width:900px)": {
          margin: "8px",
          padding: "8px",
        },
      }}
    >
      <form onSubmit={handleDefaultsSave} style={{ textAlign: "center" }}>
        <TableContainer component={Paper} sx={{ marginBottom: "32px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" fontWeight="bold" sx={headingStyle}>
                    <TranslationContent contentID={"name"} />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" fontWeight="bold"  sx={headingStyle}>
                    <TranslationContent
                      contentID={"technical_warranty_length"}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" fontWeight="bold"  sx={headingStyle}>
                    <TranslationContent
                      contentID={"aesthetic_warranty_length"}
                    />
                  </Typography>
                </TableCell>
                {(width > 600 || edit) && (
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold"  sx={headingStyle}>
                      <TranslationContent
                        contentID={"functional_warranty_length"}
                      />
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {warrantyDefaults.products?.map((product, index) => (
                <React.Fragment key={product.id + index}>
                  {product.quality_classes?.map((quality_class) => (
                    <TableRow key={quality_class.name}>
                      <TableCell>
                        <Typography variant="subtitle2"  sx={headingStyle}>
                          <TranslationContent
                            contentID={product.product_type}
                          />
                          {` - ${quality_class.name}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {edit ? (
                          <TextField
                            size="small"
                            type="number"
                            defaultValue={
                              quality_class.warranty_length_technical
                            }
                            variant="outlined"
                            fullWidth
                            onChange={(event) =>
                              handleQualityClassChange(
                                event,
                                index,
                                quality_class.name,
                                "warranty_length_technical"
                              )
                            }
                          />
                        ) : (
                          <Typography variant="subtitle1">
                            {quality_class.warranty_length_technical
                              ? quality_class.warranty_length_technical
                              : "-"}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {edit ? (
                          <TextField
                            size="small"
                            type="number"
                            defaultValue={
                              quality_class.warranty_length_aesthetic
                            }
                            variant="outlined"
                            fullWidth
                            onChange={(event) =>
                              handleQualityClassChange(
                                event,
                                index,
                                quality_class.name,
                                "warranty_length_aesthetic"
                              )
                            }
                          />
                        ) : (
                          <Typography variant="subtitle1">
                            {quality_class.warranty_length_aesthetic
                              ? quality_class.warranty_length_aesthetic
                              : "-"}
                          </Typography>
                        )}
                      </TableCell>
                      {edit && (
                        <TableCell>
                          <TextField
                            size="small"
                            type="number"
                            defaultValue={
                              quality_class.warranty_length_functional
                            }
                            variant="outlined"
                            fullWidth
                            onChange={(event) =>
                              handleQualityClassChange(
                                event,
                                index,
                                quality_class.name,
                                "warranty_length_functional"
                              )
                            }
                          />
                        </TableCell>
                      )}
                      {width > 600 && !edit && (
                        <TableCell>
                          <Typography variant="subtitle1">
                            {quality_class.warranty_length_functional
                              ? quality_class.warranty_length_functional
                              : "-"}
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          sx={{ textAlign: "center", maxWidth: "800px", margin: "auto" }}
        >
          {edit ? (
            <>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={handleEditButtonClick}
                  variant="contained"
                  sx={buttonStyle}
                >
                  <TranslationContent contentID="cancel" />
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button type="submit" variant="contained" sx={buttonStyle}>
                  <TranslationContent contentID="saveAndPublish" />
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button
                onClick={handleEditButtonClick}
                variant="contained"
                sx={buttonStyle}
              >
                <TranslationContent contentID="edit" />
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Paper>
  );
};
export default WarrantyDefaults;

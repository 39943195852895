import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import EditIcon from "@mui/icons-material/Edit";
import { useViewport } from "../../../Context/ViewportProvider";

const ModalContainer = ({ children, open, handleClose, onEdit }) => {
  const { width } = useViewport();

  return (
    <div>
      <Button onClick={onEdit} sx={{minWidth: "24px !important"}}>
        {width > 600 ? (
          <Typography variant="subtitle2">
            <TranslationContent contentID={"edit"} />
          </Typography>
        ) : (
          <EditIcon/>
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            height: "80vh",
            width: "95% !important",
            maxWidth: "1200px",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalContainer;

import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_API_KEY;

const resendEmail = async (data, accessToken, idToken) => {
  const response = await axios.post(`${apiBaseURL}/email`, 
  {
    email: data.email,
    pdfId: data.pdf,
    language: data.language,
  },
  {
    headers: {
      "x-api-key": apiKey,
      Authorization: "Bearer " + accessToken,
      "x-id-token": "Bearer " + idToken,
    },
  });

  return response;
};

const registrationsServices = {
  resendEmail,
};

export default registrationsServices;
